<template lang="pug">
.main-page.row
  el-form.demo-form-inline(:inline='true', :model='searchForm')
    el-form-item(label='车牌类型')
      CarLicenseTypeSelect(v-model='searchForm.type' clearable)
    el-form-item(label='车牌编号')
      el-input(v-model='searchForm.carNo')
    el-form-item(label='绑定状态')
      BindStatusSelect(v-model='searchForm.bind_status')
    el-form-item(label='过期时间')
      el-date-picker(
        v-model='searchForm.expire_endtime',
        type='year',
        value-format='yyyy'
        placeholder="精确到年"
        :picker-options="expireTimeOption"
      )
    el-form-item(label='绑定时间')
      el-date-picker(
        v-model='searchForm.bind',
        type='daterange',
        range-separator='至',
        start-placeholder='开始日期',
        end-placeholder='结束日期',
        value-format='yyyy-MM-dd'
      )
    el-form-item
      el-button(type='primary', @click='refresh', v-loading='loading') 查询
      el-button(type='success', @click='generate' v-if="pemission['1.2.1']") 生成车牌
      el-button(type='danger', @click='selectDelete' :disabled="!multipleSelection.length") 批量删除
      el-button(type='success', @click='ghostVisible = true' v-if="pemission['1.2.1']") 生成二维码

  el-table(
    :data='tableData'
    @selection-change='handleSelectionChange'
    stripe
    fit
    width='800'
    v-loading='loading'
  )
    el-table-column(type='selection', width='55')
    el-table-column(prop='carNo', label='车牌号码')
    el-table-column(prop='bind_status', label='使用状态')
    el-table-column(prop='bind_time', label='绑定时间')
    el-table-column(prop='ctime', label='生成时间')
    el-table-column(prop='qrCode', label='二维码')
      template(slot-scope='scope')
        el-button(
          @click='showQrCodeDialog(scope.row)',
          type='success',
          size='small'
        ) 查看
    //- el-table-column(prop='link', label='访问链接')
    el-table-column(
      prop='name',
      label='操作',
      fixed='right',
      width='120',
      align='center'
    )
      template(slot-scope='scope')
        //- 车牌状态(正常)
        el-button(
          v-if="scope.row.status == 1 && pemission['1.2.2']"
          @click='handleClick(scope.row, "loggout")',
          type='warning',
          size='small'
        ) 注销
        //- 车牌状态(已注销)
        el-button(
          v-if="scope.row.status == 2 && pemission['1.2.2']"
          @click='handleClick(scope.row, "delete")',
          type='danger',
          size='small'
        ) 删除

  el-pagination(
    background,
    layout='prev, pager, next, total',
    :page-size='pageSize',
    :total='total',
    @current-change='pageChange'
  )

  el-dialog(
    title='生成车牌',
    :visible.sync='generateVisible',
    width='50%',
    :show-close='false'
    :loading="loading"
  )
    .row
      el-form.demo-form-inline(:model='generateForm', label-width='120px')
        el-row
          el-col(:span='12')
            el-form-item(label='车牌类型')
              CarLicenseTypeSelect(v-model='generateForm.type' @input="getGenerateIndex")
        el-row
          el-col(:span='12')
            el-form-item(label='到期时间')
              el-date-picker(
                v-model="generateForm.expire_at"
                type="year"
                placeholder="精确到年"
                :picker-options="expireTimeOption"
                @change="getGenerateIndex"
                value-format='yyyy'
              )
        el-row
          el-col(:span='12')
            el-form-item(label='生成数量')
              el-input-number(v-model='generateForm.number' :min="1" :max="9999")
        el-row(v-show="generateHint" v-loading="generateLoading")
          el-alert(
            :title="generateHint"
            type="success"
            :closable="false"
          )

    span.dialog-footer(slot='footer')
      el-button(@click='generateVisible = false') 取 消
      el-button(type='primary', @click='handleGenerate') 生成

  el-dialog(
      title='生成二维码',
      :visible.sync='ghostVisible',
      width='50%',
      :show-close='false'
      :loading="loading"
    )
      .row
        el-form.demo-form-inline(:model='factoryForm', label-width='120px')
          el-row
            el-col(:span='12')
              el-form-item(label='车牌类型')
                CarLicenseTypeSelect(v-model='factoryForm.type')
          el-row
            el-col(:span='12')
              el-form-item(label='到期时间')
                el-date-picker(
                  v-model="factoryForm.expire_at"
                  type="year"
                  placeholder="精确到年"
                  :picker-options="expireTimeOption"
                  value-format='yyyy'
                )
          el-row
            el-col(:span='12')
              el-form-item(label='生成起始')
                el-input-number(v-model='factoryForm.start' :min="1" :max="9999" :step="1")
              el-form-item(label='生成数量')
                el-input-number(
                  v-model='factoryForm.number'
                  :min="1" :step="1"
                  :max="9999 - factoryForm.start"
                )
          el-row(v-show="factoryHint")
            el-alert(
              :title="factoryHint"
              type="success"
              :closable="false"
            )

      span.dialog-footer(slot='footer')
        el-button(@click='ghostVisible = false') 取 消
        el-button(type='primary', @click='handleFactory') 生成

  el-dialog(
    title='二维码',
    :visible.sync='qrCodeVisible',
    width='500px',
    :show-close='false',
    :close-on-click-modal='false',
    :close-on-press-escape='false'
    :loading="loading"
  )
    el-row(type='flex', justify='center')
      div(class="qrcode" id="singlecode")
        .top
          img.pic(src='~@/common/images/J.png' v-show="qrForm.type === 'j'")
          img.pic(src='~@/common/images/W.png' v-show="qrForm.type === 'w'")
          img.pic(src='~@/common/images/X.png' v-show="qrForm.type === 'x'")
          img.pic(src='~@/common/images/y.png' v-show="qrForm.type === 'y'")
          .text {{ qrForm.text }}
        .bottom
          img.logo(src='~@/common/images/5.png')
          img#code(:src='qrForm.qrCodeImg' class="code")
    span.dialog-footer(slot='footer')
      el-button(@click='qrCodeVisible = false') 取 消
      el-button(type='primary', @click='singleDownload') 下载

  div(class="qrcode" id="ghostcode" :class="{'hide': !ghost.show}")
    .top
      img.pic(src='~@/common/images/J.png' v-show="ghost.type === 'j'")
      img.pic(src='~@/common/images/W.png' v-show="ghost.type === 'w'")
      img.pic(src='~@/common/images/X.png' v-show="ghost.type === 'x'")
      img.pic(src='~@/common/images/y.png' v-show="ghost.type === 'y'")
      .text {{ ghost.text }}
    .bottom
      img.logo(src='~@/common/images/5.png')
      img#ghostcode(:src='ghost.qrCodeImg' class="code")
</template>

<script>
import QRCode from 'qrcode';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import CarLicenseTypeSelect from '@/components/CarLicenseTypeSelect.vue';
import BindStatusSelect from '@/components/BindStatusSelect.vue';
import { BIND_STATUS_TEXT, getPemission } from '@/utils/const';
import request from '@/utils/request';

export default {
  components: {
    CarLicenseTypeSelect,
    BindStatusSelect,
  },
  data() {
    return {
      pemission: getPemission(),
      searchForm: {
        type: '',
        bind_status: '',
        expire_endtime: '',
        bind: [],
        carNo: '',
      },
      loading: false,
      generateLoading: false,
      tableData: [],
      total: 0,
      pageSize: 20,
      pageIndex: 1,
      multipleSelection: [],
      generateVisible: false,
      generateForm: {
        type: 'x',
        expire_at: `${new Date().getFullYear()}`,
        start: '',
        number: '',
      },
      factoryForm: {
        type: 'x',
        expire_at: `${new Date().getFullYear()}`,
        start: '',
        number: '',
      },
      qrCodeVisible: false,
      qrForm: {
        text: '',
        qrCodeImg: '',
        type: '',
      },
      ghost: {
        text: '',
        qrCodeImg: '',
        type: '',
        show: false,
      },
      ghostVisible: false,
      expireTimeOption: {
        disabledDate(date) {
          // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          return date.getTime() < Date.now();
        },
      },
      generateHint: '',
    };
  },
  computed: {
    factoryHint() {
      const {
        type, expire_at: expireAt, start, number,
      } = this.factoryForm;
      const year = expireAt.substr(2);
      const from = this.formatZero(start, 4);
      const to = this.formatZero(Number(start) + (number - 1), 4);
      return `将从${type}${year}-${from} 生成到 ${type}${year}-${to}`;
    },
  },
  methods: {
    refresh() {
      this.pageIndex = 1;
      this.search();
    },
    search() {
      const params = {
        ...this.searchForm,
        page: this.pageIndex,
        pagesize: this.pageSize,
      };
      if (this.searchForm.bind && this.searchForm.bind.length) {
        const [timeFrom, timeTo] = this.searchForm.bind;
        params.bind_starttime = timeFrom;
        params.bind_endtime = timeTo;
      }
      delete params.bind;
      if (params.expire_endtime) {
        params.expire_endtime += '-09-01';
      }
      this.getData(params);
    },
    getData(params) {
      this.loading = true;
      request({
        method: 'get',
        url: '/manager/carno/getList',
        params,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.total = res.data.count;
            this.tableData = res.data.list.map((e) => ({
              ...e,
              bind_status: BIND_STATUS_TEXT[e.bind_status],
              bind_time: e.bind_time || '未绑定',
              link: 'https://www.test.com',
            }));
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(console.error)
        .then(() => {
          this.loading = false;
        });
    },
    generate() {
      this.getGenerateIndex();
      this.generateVisible = true;
    },
    getGenerateIndex() {
      if (!this.generateForm.expire_at) return;
      this.generateLoading = true;
      request({
        method: 'get',
        url: '/manager/carno/getCarnoRecord',
        params: {
          year: this.generateForm.expire_at.substr(2),
          type: this.generateForm.type,
        },
      })
        .then((res) => {
          if (res.code === 1000) {
            this.generateForm.start = res.data.end_index + 1;
            this.generateHint = `当前条件下，已生成车牌共计${res.data.end_index || 0}张，已绑定${res.data.bindNum}张`;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {})
        .then(() => {
          this.generateLoading = false;
        });
    },
    handleGenerate() {
      const {
        number, type, expire_at: expireAt,
      } = this.generateForm;
      if (!number) {
        this.$message.warning('请输入生成数量');
        return;
      }
      if (!expireAt) {
        this.$message.warning('请选择车牌到期时间');
        return;
      }
      const params = {
        type,
        number,
        year: `${expireAt}`.substr(2), // 2021 -> 21
        expire_at: expireAt,
      };
      console.log(params);
      this.loading = true;
      request({
        method: 'post',
        url: '/manager/carno/createCarno',
        data: params,
      })
        .then((res) => {
          if (res.code === 1000) {
            this.$message.success(res.msg || '创建成功');
            this.generateVisible = false;
            this.search();
            this.$nextTick(() => {
              this.doFactory(type, this.generateForm.start || 1, `${expireAt}`, number);
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    selectDelete() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择要删除的数据');
        return;
      }
      // scope.row.status == 2
      if (this.multipleSelection.some((select) => select.status !== 2)) {
        this.$message.warning('请先注销车牌后再删除');
        return;
      }
      const ids = this.multipleSelection.map((e) => e.carNo);
      this.$confirm('此操作将永久删除所选择的车牌号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          this.doDelete(ids, true);
        })
        .catch((e) => {
          console.error(e);
          this.$message.info('已取消');
        });
      // console.log(e);
    },
    handleClick(row, target) {
      if (target === 'delete' && +row.bind_status === 2) {
        this.$message.error('该车牌正在使用中，请先注销后再删除！');
        return;
      }

      const hint = target === 'loggout' ? '注销' : '删除';
      this.$confirm(`此操作将永久${hint}该车牌号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          if (target === 'loggout') {
            this.doDelete(row.carNo);
          } else if (target === 'delete') {
            this.doDelete(row.carNo, true);
          }
        })
        .catch((e) => {
          console.error(e);
          this.$message.info('已取消');
        });
    },
    doDelete(carnos, isDelete = false) {
      this.loading = true;
      request({
        method: 'post',
        url: `/manager/carno/${isDelete ? 'delete' : 'cancel'}`,
        data: {
          carnos,
        },
      })
        .then((res) => {
          if (res.code === 1000) {
            this.$message.success(res.msg || '操作成功');
            this.search();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    pageChange(e) {
      this.pageIndex = e;
      this.search();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    async showQrCodeDialog(row) {
      this.initQrCode(row).then(() => {
        this.qrCodeVisible = true;
      });
    },

    async initQrCode(row) {
      this.qrForm = {
        type: row.type,
        text: row.carNo.substr(1),
        name: row.carNo,
      };
      try {
        const clientId = 1; // 类似服务商，预留字段
        const mpLink = `https://znmzcp.fengyu.info/mp/?carno=${row.carNo}&clientId=${clientId}`;
        const url = await QRCode.toDataURL(mpLink, {
          errorCorrectionLevel: 'H',
          margin: 1,
        });
        this.qrForm.qrCodeImg = url;
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * 下载单张图片
     */
    async singleDownload() {
      this.loading = true;
      try {
        const canvas = await html2canvas(document.getElementById('singlecode'), { scale: 4 });
        const url = canvas.toDataURL(this.qrForm.name);
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', this.qrForm.name);
        a.click();
      } catch (e) {
        this.$message.error('下载失败');
      } finally {
        this.loading = false;
      }
    },

    handleFactory() {
      const {
        type,
        start,
        expire_at: expireAt,
        number,
      } = this.factoryForm;

      this.doFactory(type, start, expireAt, number);
    },

    doFactory(type, start, expireAt, number) {
      console.log(type, start, expireAt, number);
      const data = [];
      if (!start || !number || !expireAt) {
        this.$message.warning('请填写完整');
      }
      for (let i = 0; i < number; i += 1) {
        const no = this.formatZero(Number(start) + i, 4);
        const carno = `${type}${expireAt.substr(2)}-${no}`;
        data.push({
          name: carno,
          link: `https://znmzcp.fengyu.info/mp/?carno=${carno}&clientId=1`,
          type,
        });
      }
      // console.log(data);
      // const data = [
      //   { link: 'https://znmzcp.fengyu.info/mp/?carno=x22-0001&clientId=1', name: 'x22-0001' },
      // ];
      this.factoryCreate(data);
    },

    stop(time = 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },

    async factoryCreate(data) {
      console.time('init =========');
      this.ghost.show = true;
      this.ghostVisible = false;
      const loading = this.$loading({
        lock: true,
        text: `生成二维码中。。。0/${data.length}`,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      const finish = () => {
        loading.close();
        this.ghost.show = false;
        console.timeEnd('init =========');
      };

      await this.stop(30);

      try {
        const zip = new JSZip();
        const generateFile = async (item) => {
          this.ghost.loadImg = false;
          const qrUrl = await QRCode.toDataURL(item.link, {
            errorCorrectionLevel: 'H',
            margin: 1,
          });

          // console.log(qrUrl);
          this.ghost.qrCodeImg = qrUrl;
          this.ghost.type = item.type;
          this.ghost.text = item.name.substr(1); // x21-0001 -> 21-0001
          await this.stop(0);

          const canvas = await html2canvas(document.getElementById('ghostcode'), { scale: 4 });
          const url = canvas.toDataURL(item.name);
          zip.file(`${item.name}.png`, url.split('base64,')[1], { base64: true });
        };

        // 因为dom只有一个，所以只能一个个的生成，如果用promise.all 那最后生成的都是一样的码
        for (let i = 0; i < data.length; i += 1) {
          const item = data[i];
          // eslint-disable-next-line
          await generateFile(item);
          loading.setText(`生成二维码中。。。${i + 1}/${data.length}`);
        }

        // 等文件插入完成
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, '车辆二维码.zip');
          finish();
        });
      } catch (e) {
        this.$message.error(e.message);
        finish();
      }
    },
    formatZero(num, len) {
      if (String(num).length > len) return num;
      return (Array(len).join(0) + num).slice(-len);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="less" scoped>
#ghostcode.hide {
  visibility: hidden;
}

.qrcode {
  width: 400px;
  height: 200px;
  border-radius: 20px;
  background-color: rgb(16, 116, 188);
  padding: 20px 10px;

  .top {
    display: flex;
    align-items: center;

    .pic {
      width: 100px;
      height: 100px;
      margin: auto;
    }
    .text {
      color: white;
      font-size: 60px;
      margin: auto;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 200px;
      height: 43px;
    }

    .code {
      margin-left: 30px;
      width: 68px;
      height: 68px;
      transform: scale(1.2);
    }
  }
}
</style>
